import React, { useState, useContext } from "react";
import "../login/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo.jpeg";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { REGISTER_API } from "../../../AllApi/addStaffApi";
import UserContext from "../../../UserContext";
import LoginNavbar from "../login/LoginNavbar";

const ForgotPassword = () => {
  const { otp, setOTP, setResetEmail, resetEmail, otpPage, setOTPPage } =
    useContext(UserContext);
  const [email, setEmail] = useState("");
  const [enterOTP, setEnterOTP] = useState("");
  const [display, setDisplay] = useState(false);
  const [timerCount, setTimerCount] = useState(10);
  const navigate = useNavigate();

  const VerifyEmail = (e) => {
    e.preventDefault();
    if (otpPage === "true") {
      if (enterOTP) {
        if (parseInt(enterOTP) === otp) {
          setOTPPage("false");
          navigate("/newpassword");
        } else {
          toast.error(
            "The code you have entered is not correct, try again  re-send the code",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      } else {
        toast.error("Please enter verification code", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      if (email) {
        REGISTER_API.VerifyEmail(email).then((resp) => {
          if (resp.status === 200) {
            document.getElementById("email_success").style.display = "block";
            setDisplay(true);
            const OTP = Math.floor(Math.random() * 9000 + 1000);
            setOTP(OTP);
            setResetEmail(email);
            setOTPPage("true");
            const data = {
              otp: OTP,
              email: email,
            };
            REGISTER_API.SendOTPEmail(data);
            // Timer count
            var timeC = 10;
            var timer = setTimeout(countdown, 1000);
            function countdown() {
              timeC--;
              if (timeC >= 0) {
                setTimerCount((prev) => prev - 1);
                setTimeout(countdown, 1000);
              } else if (timeC < 0) {
                clearTimeout(timer);
                setDisplay(false);
              }
            }
          } else {
            document.getElementById("email_error").style.display = "block";
            document.getElementById("email_success").style.display = "none";
          }
        });
      } else {
        toast.error("Please enter your email", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const ResendOTP = () => {
    setTimerCount((prev) => prev + 10);
    const OTP = Math.floor(Math.random() * 9000 + 1000);
    setOTP(OTP);
    setOTPPage("true");
    const data = {
      otp: OTP,
      email: resetEmail,
    };
    REGISTER_API.SendOTPEmail(data);
    // Timer count
    setDisplay(true);
    var timeC = 10;
    var timer = setTimeout(countdown, 1000);
    function countdown() {
      timeC--;
      if (timeC > 0) {
        setTimerCount((prev) => prev - 1);
        setTimeout(countdown, 1000);
      } else if (timeC === 0) {
        setTimerCount(0);
        clearTimeout(timer);
        setDisplay(false);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="top-content">
        <div className="inner-bgs">
          <div className="container-fluid">
            <div className="row">
              {/* <div className="bgoffsetbg"> */}
              <div className="col-lg-4 col-md-6 col-sm-12 bgoffsetbg">
                <div
                  className="loginbg"
                  style={{ width: "-webkit-fill-available" }}
                >
                  <div className="form-top">
                    <div className="form-top-left logowidth">
                      <img
                        src={ieclogo}
                        style={{ borderRadius: "50%", height: "90px" }}
                        alt="Colege ERP"
                      />
                    </div>
                  </div>
                  <div className="form-bottom">
                    <form onSubmit={VerifyEmail}>
                      {otpPage === "false" ? (
                        <div>
                          <h5>FORGOT PASSWORD</h5>
                          <p />
                          <div className="form-group mt-4">
                            <input
                              id="rdbCollege"
                              type="radio"
                              name="rdbGN"
                              defaultChecked="checked"
                              className="me-1"
                            />
                            <label htmlFor="rdbCollege">Admin/Hod</label>
                            <input
                              id="rdbStudent"
                              type="radio"
                              name="rdbGN"
                              className="mx-2 me-1"
                            />
                            <label htmlFor="rdbStudent">Teacher</label>
                            <input
                              id="rdbStudent"
                              type="radio"
                              name="rdbGN"
                              className="mx-2 me-1"
                            />
                            <label htmlFor="rdbStudent">Student</label>
                            <input
                              id="rdbStudent"
                              type="radio"
                              name="rdbGN"
                              className="mx-2 me-1"
                            />
                            <label htmlFor="rdbStudent">Guardian</label>
                          </div>
                          <div id="divDetails">
                            <div className="form-group mt-4">
                              <input
                                name="txtUsername"
                                type="text"
                                id="txtUsername"
                                className="form-control"
                                placeholder="Email"
                              />
                            </div>
                            <div className="d-grid mb-3 mt-4">
                              <button
                                type="submit"
                                className="btn btns"
                              >
                                Send OTP
                              </button>
                            </div>
                            <p className="text-center">
                              <NavLink
                                className="forgot"
                                style={{ fontSize: "16px" }}
                                to="/login"
                              >
                                <i class="fa-solid fa-right-to-bracket"></i>{" "}
                                Login
                              </NavLink>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h5>FORGOT PASSWORD</h5>
                          <p />
                          <div id="divDetails">
                            <div className="form-group mt-4 mb-4">
                              <input
                                type="text"
                                name="enterOTP"
                                onChange={(e) => setEnterOTP(e.target.value)}
                                value={enterOTP}
                                className="form-control"
                                placeholder="OTP"
                              />
                            </div>
                            <div className="d-grid mb-3">
                              <button
                                type="submit"
                                className="btn text-light p-2"
                              >
                                Submit OTP
                              </button>
                            </div>
                            <NavLink
                              to="#"
                              color="link"
                              className="forgot-password"
                              onClick={ResendOTP}
                            >
                              Didn't receive code?
                              <span
                                className="text-danger mx-1"
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                }}
                              >
                                {display
                                  ? `Resent OTP in ${timerCount}s`
                                  : "Resend OTP"}
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12 nopadding-2">
                <div
                  className="d-flex align-items-center text-wrap flex-column justify-content-center bg-position-sm-left bg-position-lg-center"
                  style={{
                    background:
                      'url("https://demo.smart-school.in/uploads/school_content/login_image/1663064530-1070210809632059d2b8b0b!1662796232-1721792380631c41c80d038!login_bg3.jpg") no-repeat',
                    backgroundSize: "cover",
                  }}
                >
                  <div className=" bg-shadow-remove ">
                    <h3 className="h3">
                      What's New In IEC College of Engineering{" "}
                    </h3>
                    <div className="loginright mCustomScrollbar _mCS_1">
                      <div
                        id="mCSB_1"
                        className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                        style={{ maxHeight: "none" }}
                        tabIndex={0}
                      >
                        {/* <div
                          id="mCSB_1_container"
                          className="mCSB_container"
                          style={{
                            position: "relative",
                            top: "-30px",
                            left: 0,
                          }}
                          dir="ltr"
                        >
                          <div className="messages">
                            <h4>Teachers' Day Celebration</h4>
                            <p>
                              Teachers’ Day was celebrated in school with great
                              enthusiasm on Thursday, 5th September. The...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/teachers-day-celebration-1"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>
                              The Opening Ceremony of Computer Science Month
                            </h4>
                            <p>
                              Computer science is a rapidly growing field that
                              is...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/the-opening-ceremony-of-computer-science-month"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>Class III to V - Patriotic Song Competition</h4>
                            <p>
                              A patriotic song competition will be held for
                              students of classes III to V, encouraging them
                              to...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/class-iii-to-v-patriotic-song-competition"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>Webinar on Time Management.</h4>
                            <p>
                              Dear Parents/Guardians and Students, &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/webinar-on-time-management"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                            <h4>
                              Webinar for the Students of Class IX to XII on
                              Career information.
                            </h4>
                            <p>
                              Dear Parents/Guardians and Student, &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;...{" "}
                              <a
                                className="more"
                                href="https://demo.smart-school.in/read/webinar-for-the-students-of-class-ix-to-xii-on-career-information"
                                target="_blank"
                              >
                                Read More
                              </a>
                            </p>
                            <div className="logdivider" />
                          </div>
                        </div> */}
                        <div
                          id="mCSB_1_scrollbar_vertical"
                          className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                          style={{ display: "block" }}
                        >
                          <div className="mCSB_draggerContainer">
                            <div
                              id="mCSB_1_dragger_vertical"
                              className="mCSB_dragger"
                              style={{
                                display: "block",
                                height: 126,
                                maxHeight: 260,
                                top: 7,
                              }}
                            >
                              <div
                                className="mCSB_dragger_bar"
                                style={{ lineHeight: 0 }}
                              />
                            </div>
                            <div className="mCSB_draggerRail" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*./col-lg-6*/}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
