import React, { useContext } from "react";
import CIcon from "@coreui/icons-react";
import {
  cilSchool,
  cilCarAlt,
  cilHouse,
} from "@coreui/icons";
import { PiUsersThree } from "react-icons/pi";
import { FaRegCheckCircle,FaChartBar,FaRegUser, FaAngleDoubleRight, FaUserPlus, FaMapSigns, FaSitemap, FaBook, FaBus, FaBuilding, FaChartLine, FaRupeeSign, FaRegCreditCard, FaRegObjectGroup, FaMoneyBillAlt, FaCalendarCheck   } from "react-icons/fa";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { MdOutlineMap, MdOutlineMenuBook } from "react-icons/md";
import { CNavGroup, CNavItem } from "@coreui/react";
import UserContext from "../UserContext";
import { GoMortarBoard } from "react-icons/go";
import { FaIndianRupeeSign } from "react-icons/fa6";

const Sidebar_Menu = [
  {
    component: CNavGroup,
    name: "Student Information",
    icon: <FaUserPlus className=" nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Student Details",
        to: "/admin/student/details",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Disabled Students",
        to:"/student/disablestudentslist",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Student Categories",
        to:"/category",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Fees Collection",
    icon: <FaMoneyBillAlt icon={cilSchool} className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Collect Fees",
        to: "/studentfee",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Search Fees Payment",
        to: "/studentfee/searchpayment",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Search Due Fees",
        to: "/studentfee/feesearch",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Master",
        to: "/admin/feemaster",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Group",
        to: "/admin/feegroup",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Type",
        to: "/admin/feetype",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Discount",
        to: "/admin/feediscount",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Carry Forward",
        to: "/admin/feesforward",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Reminder",
        to: "/admin/feereminder/setting",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Behaviour Records",
    icon: <FaMapSigns className=" nav-icon menu-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Assign Incident",
        to: "/behaviour/studentincidents",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Incidents",
        to:"/behaviour/incidents",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Reports",
        to:"/behaviour/report",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Income",
    icon: <FaIndianRupeeSign className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Income",
        to: "/admin/income",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Search Income",
        to:"/admin/income/searchincome",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Income Head",
        to:"/admin/incomehead",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Expenses",
    icon: <FaRegCreditCard className=" nav-icon menu-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Expense",
        to: "/admin/expense",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Search Expense",
        to:"//admin/expense/expensesearch",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Expense Head",
        to:"/admin/expensehead",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Examination",
    icon: <MdOutlineMap className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Exam Grade",
        to: "/admin/exam/grade/index",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Exam Term",
        to: "/admin/exam/index",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Schedule",
        to: "/admin/employeeattendence/index",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Attendance",
        to: "/admin/attendence/absentsms/index",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      }
    ],
  },
  {
    component: CNavGroup,
    name: "Attendance",
    icon: <FaCalendarCheck className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Delete Attendance",
        to: "/admin/stuattendence/delete",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Academics",
    icon: <GoMortarBoard className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Course",
        to: "/admin/course/add",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Branch",
        to: "/admin/branch/add",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Session",
        to: "/administrator/year/index",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  
  {
    component: CNavGroup,
    name: "Human Resource",
    icon: <FaSitemap className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Staff Directory",
        to: "/admin/staff",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Staff Attendance",
        to: "/admin/staffattendance",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Payroll",
        to: "/admin/payroll",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Approve Leave Request",
        to: "/admin/leaverequest/leaverequest",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Apply Leave",
        to: "/admin/staff/leaverequest",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Leave Type",
        to: "/admin/leavetypes",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Role",
        to: "/admin/role/index",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Department",
        to: "/admin/department",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Desgination",
        to: "/admin/desgination",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Disabled Staff",
        to: "/admin/staff/disablestafflist",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Library",
    icon: <FaBook icon={cilSchool} className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Book List",
        to: "/admin/book/all",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Issue & Return",
        to: "/admin/members",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Add Student",
        to: "/admin/member/student",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Add Staff Member",
        to: "/admin/member/staff",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Inventory",
    icon: <FaRegObjectGroup icon={cilSchool} className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Issue Item",
        to: "/admin/issueitem",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Add Item Stock",
        to: "/admin/itemstock",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Add Item",
        to: "/admin/item",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Item Category",
        to: "/admin/itemcategory",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Item Store",
        to: "/admin/itemstore",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Item Supplier",
        to: "/admin/itemsupplier",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Transport",
    icon: <FaBus icon={cilCarAlt} className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Routes",
        to: "/admin/route",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Vehicles",
        to: "/admin/vehicle",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Assign Vehicle",
        to: "/admin/vehicle-route",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Hostel",
    icon: <FaBuilding icon={cilHouse} className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Hostel Rooms",
        to: "/admin/hostelroom",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Room Type",
        to: "/admin/roomtype",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Hostel",
        to: "/admin/hostel",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Reports",
    icon: <FaChartLine className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Attendance Report",
        to:"/attendancereports/attendance",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Front CMS",
    icon: <FaChartLine className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Events",
        to:"/admin/front/events",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
];

export default Sidebar_Menu;
